import Head from "next/head";
import React from "react";
import Tools from "../../../tools/Tools";
const defaultTitle = "Работа в Москве - Свежие вакансии в городе Москва на avatarom.com";
const defaultDescription = "Свежие вакансии в Москве, удалённая работа без опыта, актуальные вакансии каждый день";

const toCanonicalTitle = (title) => {
   return title.substr(0, 120);
};
const toCanonicalDescription = (description) => {
   return Tools.toCanonicalString(description).substr(0, 200);
};

const AvataromHead = ({title = defaultTitle,
                      description = defaultDescription,
                      canonicalUrl = null,
                      image = "/logo.png"}) => {

        const canonicalTitle = toCanonicalTitle(title);
        const canonicalDescription = toCanonicalDescription(description);
        const canonicalPage = canonicalUrl
            ? <link rel="canonical" href={canonicalUrl} />
            : null;

        return <Head>
                <title>{canonicalTitle}</title>
                <meta name="viewport" content="width=device-width, user-scalable=no"/>
                <meta name="description" content={canonicalDescription}/>
                <meta name="author" content="Avatarom"/>
.
                {/*og*/}
                <meta name="og:title" content={canonicalTitle}/>
                <meta name="og:description" content={canonicalDescription}/>
                <meta name="og:site_name" content="avatarom.com"/>
                <meta name="og:url" content="https://avatarom.com"/>
                <meta name="og:type" content="website"/>
                <meta name="og:image" content={image} />

                {/*twitter*/}
                <meta name="twitter:title" content={canonicalTitle}/>
                <meta name="twitter:description" content={canonicalDescription}/>
                <meta name="twitter:site" content="avatarom.com"/>
                <meta name="twitter:url" content="https://avatarom.com"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:creator" content="Avatarom"/>
                <meta name="twitter:image:src" content={image} />
                <meta name="theme-color" content="#f3f3f3"/>

                {canonicalPage}

                <link rel="icon" href="/favicon.svg" type="image/svg" />
                <link rel="mask-icon" href="/static/mask-icon.svg" color="#000000"/>
                <link rel="apple-touch-icon" href="/static/apple-touch-icon.png"/>
                <link rel="manifest" href="/manifest.json"/>
                <link rel="stylesheet" href="/semantic.min.css"/>

                <meta name="yandex-verification" content="49650dae4725417e" />
                <meta name="google-site-verification" content="F1ISI0QMjptvptqLxf-6PQVj3YZhFcTNAntolsD88co" />
        </Head>
};

export default AvataromHead