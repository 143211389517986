import React, {Fragment} from "react";
import {Provider} from "react-redux";
import App from "next/app";
import withRedux from "next-redux-wrapper";
import {storeFactory} from "../src/store/store";
import {getUser} from "../src/actions/userActions";
import Layout from "../src/components/Shared/Layout/Layout";
import '../styles/styles.less';
import {getCategories, getProfessions, getRegions, getTags, setRegion} from "../src/actions/optionsActions";
import cookies from 'next-cookies';
import getConfig from 'next/config';
import * as Sentry from '@sentry/browser';
import ExtraScript from "../src/scripts/ExtraScript";
const { publicRuntimeConfig } = getConfig();

Sentry.init({
    dsn: publicRuntimeConfig.sentry.dsn
});

const makeStore = (initialState, options) => {
    return storeFactory(initialState);
};
const defaultRegion = {id: 19, title: "Москва"};

class AvataromApp extends App {

    static async getInitialProps({Component, ctx}) {
        let region = defaultRegion;
        if(ctx.isServer){
            //ctx.store.dispatch(getUser());
            await ctx.store.dispatch(getRegions());
            await ctx.store.dispatch(getProfessions());
            await ctx.store.dispatch(getTags());
            await ctx.store.dispatch(getCategories());

            const regionId = cookies(ctx).regionId;
            if(regionId){
                const regions = ctx.store.getState().options.regions;
                if(regions){
                    const userRegion  = regions.find(r => r.id == regionId);
                    if(userRegion){
                        region = userRegion;
                    }
                }
            }
            await ctx.store.dispatch(setRegion(region));
        }
        const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
        return {pageProps};
    }

    componentDidCatch(error, errorInfo) {
        Sentry.withScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key]);
            });

            Sentry.captureException(error);
        });
        super.componentDidCatch(error, errorInfo);
    }

    render() {
        const {Component, pageProps, store} = this.props;
        return (
            <Fragment>
                <ExtraScript />
                <Provider store={store}>
                    <Layout useNavbar={pageProps.useNavbar}
                            useFooter={pageProps.useFooter}>
                        <Component {...pageProps} />
                    </Layout>
                </Provider>
            </Fragment>
        );
    }
}

export default withRedux(makeStore)(AvataromApp);