import {Actions} from "../constants/actions";

const defaultState = {
    token: "jwt_token",
    name: "Avatarom",
};

const userReducer = (state = defaultState, action) => {
    switch (action.type) {
        case Actions.SET_USER:
            return action.payload.user;

        case Actions.LOGOUT:
            return {};
    }
    return state;
}

export default userReducer;