import React from "react";
import "./Footer.less";
import Link from "next/link";
import {connect} from "react-redux";
import Tools from "../../../tools/Tools";
import RespondingLink from "../Links/RespondingLink";
import EmployerLink from "../Links/EmployerLink";
import Logo from "../../../../public/images/logo.svg";
import {Image, Header} from "semantic-ui-react";

const mapStateToProps = state => (
    {
        region: state.options.region,
    }
);

const Footer = ({region}) => {

    const baseLink = `/rabota/${Tools.transliterate(region.title)}`;

    return (
        <div className="footer">
            <div className="footer-links">
                <Link href="/" as={"/"}>
                    <a>
                        <Image className={"logo"} src={Logo} alt={"Аватаром свежие вакансии"} />
                    </a>
                </Link>
                <Header as={"a"} className="link" href={`mailto:we@avatarom.com`} rel={"nofollow"} >
                    we@avatarom.com
                </Header>
            </div>
            <div className="footer-links">
                <Link href={"/regions"} as={"/regions"}>
                    <a className="link">
                        Работа в регионах
                    </a>
                </Link>
                <EmployerLink text={"Разместить вакансию"} className="link" rel={"nofollow"} />
                <RespondingLink text={"Разместить резюме"} className="link" rel={"nofollow"} />
            </div>
            <div className="footer-links">
                <Link href="/rabota/[...slug]" as={baseLink + "/vahtovii-metod"}>
                    <a className="link">Вакансии на вахту</a>
                </Link>
                <Link href="/rabota/[...slug]" as={baseLink + "/podrabotka"}>
                    <a className="link">Вакансии для подработки</a>
                </Link>
                <Link href="/rabota/[...slug]" as={baseLink + "/bez-opita"}>
                    <a className="link">Вакансии без опыта работы</a>
                </Link>
                <Link href="/rabota/[...slug]" as={baseLink + "/mozhno-do-18-let"}>
                    <a className="link">Вакансии для работников до 18 лет</a>
                </Link>
                <Link href="/rabota/[...slug]" as={baseLink + "/nepolnii-rabochii-den"}>
                    <a className="link">Вакансии на неполный рабочий день</a>
                </Link>
                <Link href="/rabota/[...slug]" as={baseLink + "/dostupno-♿"}>
                    <a className="link">Вакансии для людей с ограниченными возможностями</a>
                </Link>
            </div>
        </div>
    );
}

export default connect(mapStateToProps)(Footer);
