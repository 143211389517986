import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import AvataromHead from "./AvataromHead";
import "./Layout.less";

const Layout  = ({children, useNavbar = true, useFooter = true}) => {
    return (
        <div id="avatarom">

            <AvataromHead />

            {useNavbar ? <Navbar /> : null}

            <div className={useNavbar ? "content with-navbar" : "content"}>
                {children}
            </div>

            {useFooter ? <Footer /> : null}

        </div>
    );
};

export default Layout;