import {Actions} from "../constants/actions";

const defaultSearchParameters = {
    categoryId: null,
    salaryFrom: null,
    pageSize: 20,
    page: 1,
    professionId: null,
    tags: [],
    lat: null,
    lon: null,
    distance: null,
    useAround: false
};

const defaultState = {
    parameters : defaultSearchParameters,
    list: [],
    total: 0,
    nowSearching: false,
};

const vacanciesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case Actions.SET_SEARCH_PARAMETERS:
            return {
                ...state,
                parameters: {...action.parameters}
            };

        case Actions.RESET_SEARCH_PARAMETERS:
            return {
                ...state,
                parameters: {...state.parameters, ...defaultSearchParameters}
            };

        case Actions.SEARCH_VACANCIES:
            return {
                ...state,
                total: action.payload.total,
                list: action.payload.items || []
            }
            
        case Actions.MORE_VACANCIES:
            return {
                ...state,
                total: action.payload.total,
                list: action.payload.items
                    ? [...state.list, ...action.payload.items]
                    : state.list
            };

        case Actions.NEXT_PAGE:
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    page: state.parameters.page + 1
                }
            };

        case Actions.SET_REGION:
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    cityId: action.region.id
                }
            }

        case Actions.SET_PROFESSION:
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    professionId: action.profession ? action.profession.id : null
                }
            }

        case Actions.SEARCH_SIMILAR:
            return {
                ...state,
                similar: action.payload || []
            };
    }
    return state;
};

export default vacanciesReducer;