import {Actions} from "../constants/actions";
import {error} from "next/dist/build/output/log";

const loggerMiddleware = store => next => action => {
    //console.log("Dispatching: ", action.type);
    //console.log("Previous state: ", store.getState());
    next(action);
    //console.log("New state: ", store.getState());
};

const promiseMiddleware = store => next => async action => {
    if(isPromise(action.payload)){
        store.dispatch({type: Actions.PAYLOAD_STARTED, action: action.type});
        let response;
        try{
            response = await action.payload;
            if(response){
                if(response.body){
                    // todo проверка формата ответа
                    action.payload = response.body;
                }
                else if(request.text){
                    // todo проверка формата ответа
                    action.payload = JSON.parse(response.text);
                }
                store.dispatch(action);
            }
            else{
                throw error("Empty response or body");
            }
        }
        catch(error){
            console.log("ERROR: ", error);
            action.payload = undefined;
            action.error = true;
            store.dispatch({type: Actions.PAYLOAD_ERROR, action});
        }
        finally {
            store.dispatch({type: Actions.PAYLOAD_FINISHED, action: action.type});
        }
    }
    else{
        next(action);
    }
};

function isPromise(obj) {
    return obj && typeof obj.then === 'function';
}

export {loggerMiddleware, promiseMiddleware}
