import React from "react";
import {YMInitializer} from "react-yandex-metrika";
import ReactGA from 'react-ga';
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

class ExtraScript extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            didMount: false
        };
    }

    componentDidMount() {
        this.setState({
            didMount: true
        });
    }

    applyGoogleAnalytics(){
        ReactGA.initialize('UA-179011262-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        if(this.state.didMount && publicRuntimeConfig.isProd){

            this.applyGoogleAnalytics();

            return (
                <div className={"ya-metrika"}>
                    <YMInitializer accounts={[publicRuntimeConfig.yandexMetrika]}
                                   options={{
                                       webvisor: true,
                                       clickmap:true,
                                       trackLinks:true,
                                       accurateTrackBounce:true,}}  />
                </div>
            );
        }
        return null;
    }
};
export default ExtraScript;
