import {Actions} from "../constants/actions";
import Tools from "../tools/Tools";

const defaultState = {
    regions: [],
    professions: [],
    tags: [],
    categories: [],
    region: {},
    profession: {}
};

const optionsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case Actions.GET_PROFESSIONS:
            return {
                ...state,
                professions: Tools.toList(action.payload,
                    item => ({
                        id: item.id,
                        title: Tools.toUpperCaseFirstChar(item.name),
                        count: item.vacanciesCount,
                        categories: item.categories || []
                    }))
            };

        case Actions.GET_REGIONS:
            return {
                ...state,
                regions: Tools.toList(action.payload)
            };

        case Actions.GET_CATEGORIES:
            return {
                ...state,
                categories: Tools.toList(action.payload, (category) => ({
                    id: category.id,
                    title: category.title,
                    count: category.vacanciesCount
                }))
            };

        case Actions.GET_TAGS:
            return {
                ...state,
                tags: Tools.toList(action.payload)
            };

        case Actions.SET_PROFESSION:
            return {
                ...state,
                profession: action.profession
                    ? action.profession.id === -1 ? {} : action.profession
                    : {}
            };

        case Actions.RESET_SEARCH_PARAMETERS:
            return {
                ...state,
                profession: {}
            };

        case Actions.SET_REGION:
            return {
                ...state,
                region: action.region && action.region.id
                    ?  action.region
                    : state.region
            };
    }
    return state;
}

export default optionsReducer;